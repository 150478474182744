// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/aboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-four-js": () => import("./../../../src/pages/portfolio/four.js" /* webpackChunkName: "component---src-pages-portfolio-four-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-one-js": () => import("./../../../src/pages/portfolio/one.js" /* webpackChunkName: "component---src-pages-portfolio-one-js" */),
  "component---src-pages-portfolio-three-js": () => import("./../../../src/pages/portfolio/three.js" /* webpackChunkName: "component---src-pages-portfolio-three-js" */),
  "component---src-pages-portfolio-two-js": () => import("./../../../src/pages/portfolio/two.js" /* webpackChunkName: "component---src-pages-portfolio-two-js" */)
}

